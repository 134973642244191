<template>
  <v-container fluid>
    <v-row align="start" justify="center">
      <v-col cols="8">
        <v-container>
          <v-row justify="center">
            <v-col cols="auto">
              <h2>
                Ok! You can download a pre-formatted copy of your resume, or use
                the information below in your own template.
              </h2>
            </v-col>
          </v-row>
          <v-row justify="center" class="pb-3">
            <v-col cols="auto">
              <v-btn @click="tryDownload()">Download</v-btn>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row class="pt-3">
            <v-col cols="12">
              <h1>{{ resName }}</h1>
            </v-col>
            <v-col cols="12"
              ><h3>{{ address }}</h3></v-col
            >
            <v-col cols="12"><h3>Professional Summary</h3></v-col>
            <v-col cols="12"
              ><p>{{ professonalSummary }}</p></v-col
            >
            <v-col cols="12"><h3>Skills and Abilities</h3></v-col>
            <v-col cols="12">
              <p>{{ skills }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><h3>Education</h3></v-col>
          </v-row>
          <v-row v-for="edu in education" :key="edu.key">
            <v-col cols="12" sm="8">
              <strong>{{
                buildEduSchoolName(
                  edu.educationGeneral.schoolName,
                  edu.educationGeneral.universityName
                )
              }}</strong>
            </v-col>
            <v-col cols="12" sm="4">{{
              edu.educationGeneral.universityCity +
                ", " +
                edu.educationGeneral.universityState +
                ", " +
                edu.educationGeneral.country
            }}</v-col>
            <v-col cols="12" sm="8">
              <span style="italic">{{ edu.educationDegree.display }}</span>
            </v-col>
            <v-col cols="12" sm="4">{{
              buildMonthString(edu.educationGeneral.dateStart) +
                " - " +
                buildMonthString(edu.educationGeneral.dateEnd)
            }}</v-col>
            <v-col cols="12">
              <ul>
                <li v-if="shouldShowMinor(edu)">
                  {{ edu.educationDegreeMinor.display }}
                </li>
                <li v-if="shouldShowGPA(edu.educationGPA)">
                  GPA: {{ edu.educationGPA.display }}
                </li>
                <li
                  v-for="acc in validAccolades(edu.educationAccolades)"
                  :key="acc.key"
                >
                  {{ acc.display }}
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><h3>Experience</h3></v-col>
          </v-row>
          <v-row v-for="work in workExp" :key="work.key">
            <v-col cols="12" sm="8"
              ><strong>{{ work.workGeneral.companyName }}</strong></v-col
            ><v-col cols="12" sm="4">{{
              work.workGeneral.companyCity +
                ", " +
                work.workGeneral.companyState +
                ", " +
                work.workGeneral.companyCountry
            }}</v-col>
            <v-col cols="12" sm="8"
              ><p
                v-for="role in work.workRole.positions"
                :key="role.key"
                style="margin-bottom: 0px"
              >
                {{ buildPositionDisplay(role.title, role.role) }}
              </p></v-col
            >
            <v-col cols="12" sm="4">{{
              buildMonthString(work.workGeneral.startDate) +
                " - " +
                buildWorkEndString(work)
            }}</v-col>
            <v-col cols="12">
              <ul>
                <li>{{ work.workResponsibilities.description }}</li>
                <li v-for="acc in work.achievementList1" :key="acc.key">
                  {{ acc.display }}
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"><h3>Additional Experience</h3></v-col>
            <v-col cols="12">
              <ul>
                <li>Hard Skills: {{ hardSkillsDisp }}</li>
                <li>{{ languagesDisplay }}</li>
                <li>Volunteer Work: {{ volunteerDisp }}</li>
                <li>Professional Organizations: {{ professionalOrgDisp }}</li>
                <li>
                  Professional Licenses / Certifications:
                  {{ professionalCertDisp }}
                </li>
                <li>Interests: {{ interestsDisp }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as disp from "@/utility/display.js";
// import * as guid from "@/utility/guid.js";
import { ResumeBuilder } from "@/docxgen/buildResume.js";
import * as dutil from "@/utility/dateutil.js";
import { mapGetters, mapMutations, mapActions } from "vuex"; // eslint-disable-line
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "ReviewOld",
  data: () => ({
    errorMsg: false,
    resName: "FirstName, LastName",
    fileName: "Resume",
    address: "Address | Email | Phone",
    professonalSummary: "Professional Summary Text",
    skills: "Skills and Abilities Text",
    education: [],
    workExp: [],
    hardSkillsDisp: "",
    volunteerDisp: "",
    professionalOrgDisp: "",
    professionalCertDisp: "",
    interestsDisp: ""
  }),
  mounted() {
    this.resName = this.nameDisplay;
    this.address = this.addressDisplay;

    this.fileName = this.nameDisplay + " Resume";

    this.cleanEducation();
    let allEdu = Object.values(this.educationDict);
    this.education = allEdu;

    this.cleanWork();
    let allWork = Object.values(this.workDict);
    let allWorkKeys = Object.keys(this.workDict);
    var i;
    for (i = 0; i < allWork.length; i++) {
      allWork[i].key = allWorkKeys[i];
      allWork[i].workRole.positions = allWork[i].workRole?.positions?.filter(
        p => !disp.IsNullorWhitespace(p?.title)
      );

      allWork[i].achievementList1 = allWork[i].achievementList?.filter(
        a => !disp.IsNullorWhitespace(a?.display)
      );
    }

    this.workExp = allWork;
    this.buildCompanyList(this.workExp);
    this.professonalSummary = this.buildProfSummary(
      this.selectedAdjectives.map(s => s.text),
      this.calcYearsExperience(this.workExp),
      this.buildCompanyList(this.workExp),
      this.selectedRoles.map(r => r.text),
      this.hardSkills.map(h => h.text),
      this.softSkills.map(s => s.text),
      this.careerInterests.map(c => c.text)
    );

    this.skills = this.buildNoAndStringList([
      ...this.softSkills.map(s => s.text),
      ...this.selectedAdjectives.map(a => a.text)
    ]);

    this.hardSkillsDisp = this.buildStringList(
      this.hardSkills.map(s => s.text)
    );
    this.volunteerDisp = this.buildStringList(
      this.volunteerOrg.map(v => v.text)
    );
    this.professionalOrgDisp = this.buildStringList(
      this.professionalOrg.map(v => v.text)
    );
    this.professionalCertDisp = this.buildStringList(
      this.licensesCerts.map(v => v.text)
    );
    this.interestsDisp = this.buildStringList(
      this.personalInterests.map(p => p.text)
    );
  },
  computed: {
    ...mapFields("name", { nameDisplay: "displayValue" }),
    ...mapFields("address", { addressDisplay: "displayValue" }),
    ...mapFields("hardSkill", ["hardSkills"]),
    ...mapFields("softSkill", ["softSkills"]),
    ...mapFields("licenseCert", ["licensesCerts"]),
    ...mapFields("adjective", ["selectedAdjectives"]),
    ...mapFields("role", ["selectedRoles"]),
    ...mapFields("language", ["languagesDisplay"]),
    ...mapFields("organization", ["volunteerOrg", "professionalOrg"]),
    ...mapFields("personalInterest", ["personalInterests"]),
    ...mapFields("careerInterest", ["careerInterests"]),
    ...mapFields("work", ["workDict"]),
    ...mapFields("education", ["educationDict"])
  },
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapMutations("education", { cleanEducation: "cleanEntries" }),
    ...mapMutations("work", { cleanWork: "cleanEntries" }),
    tryDownload() {
      let documentBuilder = new ResumeBuilder();

      documentBuilder
        .addTitle(this.resName)
        .addAddress(this.address)
        .addProfessionalSummary("Professional Summary", this.professonalSummary)
        .addSkillsAbilities("Skills and Abilities", this.skills)
        .addEducation("Education", this.education)
        .addWorkExperience("Experience", this.workExp)
        .addAdditionalExperience(
          "Additional Experience",
          this.hardSkillsDisp,
          this.languagesDisplay,
          this.volunteerDisp,
          this.professionalCertDisp,
          this.professionalOrgDisp,
          this.interestsDisp
        )
        .applyDefaultStyles()
        .buildSection()
        .saveFile(this.fileName);
    },
    validAccolades(eduAccolades) {
      return eduAccolades.filter(a => !disp.IsNullorWhitespace(a?.display));
    },
    buildCompanyList(workExp) {
      let workArray = workExp
        .map(w => {
          if (!disp.IsNullorWhitespace(w.workGeneral?.companyName)) {
            return w.workGeneral.companyName;
          } else {
            return "";
          }
        })
        .filter(company => company.length > 0);

      return this.buildStringList(workArray);
    },
    shouldShowGPA(gpa) {
      if (gpa.gpa?.length !== 0) {
        return true;
      } else {
        return false;
      }
    },
    shouldShowMinor(edu) {
      return !disp.IsNullorWhitespace(edu?.educationDegreeMinor?.display);
    },
    buildEduSchoolName(school, university) {
      let response = "";
      if (school?.length != 0) {
        response = `${school}`;
      }
      if (school?.length != 0 && university?.length != 0) {
        response += ", ";
      }
      if (university?.length != 0) {
        response += university;
      }

      return response;
    },
    buildPositionDisplay(title, role) {
      let intStr = "";
      if (!disp.IsNullorWhitespace(title)) {
        intStr += title;

        if (!disp.IsNullorWhitespace(role)) {
          intStr += " - ";
        }
      }

      if (!disp.IsNullorWhitespace(role)) {
        intStr += role;
      }

      return intStr;
    },
    // Note: This calculation isn't really dead on. It's good enough for now, but it's imperfect.
    // It doesn't account for overlapping work experience
    // It hasn't been tested for edge cases around year cutoffs
    // It doesn't handle 0 years of experience well - not sure what do display there...
    calcYearsExperience(workExp) {
      let yearsExperience = 0.0;
      workExp.forEach(work => {
        if (
          work.workGeneral?.startDate !== "undefined" &&
          (work.workGeneral?.endDate !== "undefined" ||
            work.workGeneral.workingCurrently == true)
        ) {
          let endDate = new Date();
          if (
            work.workGeneral?.endDate !== "undefined" &&
            Object.prototype.toString.call(work.workGeneral?.endDate) ===
              "[object Date]"
          ) {
            endDate = work.workGeneral.endDate;
          }
          yearsExperience +=
            Math.abs(work.workGeneral.startDate - endDate) / 31556952000; // apx number of ms in a year
        }
      });
      let outputYears = Math.trunc(yearsExperience);
      if (outputYears == 1) {
        return `${outputYears} year`;
      } else {
        return `${outputYears} years`;
      }
    },
    buildProfSummary(
      adjectives,
      experience,
      companyList,
      roles,
      hardSkills,
      softSkills,
      careerInterests
    ) {
      let intStr = "";

      intStr += this.buildStringList(adjectives);
      intStr += " professional with ";
      intStr += experience;
      intStr += " of professionally relevant experience. ";
      if (companyList.length > 0) {
        intStr += "Experience includes acting in roles with ";
        intStr += companyList;
        intStr += ". ";
      }
      intStr += "Roles include acting as a ";
      intStr += this.buildStringList(roles);
      intStr += ". Expertise includes ";
      intStr += this.buildStringList(hardSkills);
      intStr += ". Leadership skills include ";
      intStr += this.buildStringList(softSkills);
      intStr += ". Interests include: ";
      intStr += this.buildStringList(careerInterests);
      intStr += ".";

      return intStr;
    },
    buildNoAndStringList(strList) {
      if (!Array.isArray(strList)) {
        return "";
      }

      if (strList.length == 1) {
        return strList[0];
      } else {
        let intString = "";
        let i = 0;
        for (i = 0; i < strList.length; i++) {
          intString += strList[i];

          if (i != strList.length - 1) {
            intString += ", ";
          }
        }

        return intString;
      }
    },
    buildStringList(strList) {
      if (!Array.isArray(strList)) {
        return "";
      }

      if (strList.length == 1) {
        return strList[0];
      } else if (strList.length == 2) {
        return strList[0] + " and " + strList[1];
      } else {
        let intString = "";
        let i = 0;
        for (i = 0; i < strList.length; i++) {
          intString += strList[i];

          if (i == strList.length - 2) {
            intString += ", and ";
          } else if (i != strList.length - 1) {
            intString += ", ";
          }
        }

        return intString;
      }
    },
    buildMonthString(date) {
      return dutil.dateToMonthYear(date);
    },
    buildWorkEndString(work) {
      return work.workGeneral.workingCurrently
        ? "Present"
        : dutil.dateToMonthYear(work.workGeneral.endDate);
    }
  }
};
</script>
