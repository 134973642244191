import * as docx from "docx";
import { saveAs } from "file-saver";
import * as disp from "@/utility/display.js";
import {
  HeadingLevel,
  Paragraph,
  TabStopPosition,
  TabStopType,
  TextRun,
  AlignmentType,
  LevelFormat,
  convertInchesToTwip
  //   UnderlineType,
  //   convertInchesToTwip
} from "docx";
import * as dutil from "@/utility/dateutil.js";

export class ResumeBuilder {
  childList;
  document;
  titleHeading;
  heading1;
  heading2;

  leftMarginInches = 0.5;
  rightMarginInches = 0.5;
  topMarginInches = 0.5;
  bottomMarginInches = 0.5;

  constructor() {}

  setupDocument() {
    if (this.document == null) {
      this.document = new docx.Document({
        styles: {
          default: {
            heading1: {
              basedOn: "Normal",
              next: "Normal",
              run: {
                size: 16 * 2,
                color: "#000000",
                font: "Calibri Light"
              },
              paragraph: {
                spacing: {
                  after: 120,
                  before: 240
                }
              }
            },
            heading2: {
              basedOn: "Normal",
              next: "Normal",
              run: {
                size: 13 * 2,
                color: "#000000",
                font: "Calibri Light"
              },
              paragraph: {
                spacing: {
                  before: 240,
                  after: 120
                }
              }
            },
            title: {
              basedOn: "Normal",
              next: "Normal",
              run: {
                size: 28 * 2,
                bold: false,
                color: "#000000",
                font: "Calibri Light"
              }
            },
            Normal: {
              run: {
                color: "#FF0000",
                font: "Calibri"
              }
            }
          }
        },
        paragraphStyles: [
          {
            id: "normalPara",
            name: "Normal Para",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
              color: "red"
            }
          }
        ],
        numbering: {
          config: [
            {
              reference: "my-crazy-numbering",
              levels: [
                {
                  level: 0,
                  format: LevelFormat.LOWER_LETTER,
                  text: "%1)",
                  alignment: AlignmentType.LEFT
                }
              ]
            }
          ]
        },
        sections: []
      });
    }

    if (this.titleHeading == null) {
      this.titleHeading = HeadingLevel.TITLE;
    }

    if (this.childList == null) {
      this.childList = [];
    }

    if (this.heading1 == null) {
      this.heading1 = HeadingLevel.HEADING_1;
    }

    if (this.heading2 == null) {
      this.heading2 = HeadingLevel.HEADING_2;
    }

    return this;
  }

  applyDefaultStyles() {
    this.setupDocument();

    return this;
  }

  addTitle(name) {
    this.setupDocument();
    this.childList.push(
      new Paragraph({
        children: [new TextRun(name)],
        heading: this.titleHeading
      })
    );
    return this;
  }

  addAddress(address) {
    this.setupDocument();
    this.childList.push(
      new Paragraph({
        children: [new TextRun({ text: address, font: { name: "Calibri" } })]
      })
    );
    return this;
  }

  addProfessionalSummary(summaryTitle, summaryText) {
    this.setupDocument();
    this.childList.push(
      new Paragraph({
        children: [new TextRun(summaryTitle)],
        heading: this.heading1
      }),
      new Paragraph({
        children: [
          new TextRun({ text: summaryText, font: { name: "Calibri" } })
        ]
      })
    );

    return this;
  }

  addSkillsAbilities(skillsTitle, skills) {
    this.setupDocument();
    this.childList.push(
      new Paragraph({
        children: [new TextRun(skillsTitle)],
        heading: this.heading1
      }),
      new Paragraph({
        children: [new TextRun({ text: skills, font: { name: "Calibri" } })]
      })
    );

    return this;
  }

  addEducation(educationTitle, educationArray) {
    this.setupDocument();

    this.childList.push(
      new Paragraph({
        children: [new TextRun(educationTitle)],
        heading: this.heading1
      })
    );

    educationArray.forEach(edu => {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: buildEduSchoolName(
                edu.educationGeneral.schoolName,
                edu.educationGeneral.universityName
              ),
              font: { name: "Calibri" }
            }),
            new TextRun({
              text:
                "\t" +
                edu.educationGeneral.universityCity +
                ", " +
                edu.educationGeneral.universityState +
                ", " +
                edu.educationGeneral.country,
              font: { name: "Calibri" }
            })
          ],
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position:
                TabStopPosition.MAX +
                convertInchesToTwip(
                  1 - this.leftMarginInches + (1 - this.rightMarginInches)
                ) // TabStopPosition.Max assumes 1 inch margins
            }
          ]
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: edu.educationDegree.display,
              font: { name: "Calibri" }
            }),
            new TextRun({
              text:
                "\t" +
                dutil.dateToMonthYear(edu.educationGeneral.dateStart) +
                " - " +
                dutil.dateToMonthYear(edu.educationGeneral.dateEnd),
              font: { name: "Calibri" }
            })
          ],
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position:
                TabStopPosition.MAX +
                convertInchesToTwip(
                  1 - this.leftMarginInches + (1 - this.rightMarginInches)
                ) // TabStopPosition.Max assumes 1 inch margins
            }
          ]
        })
      );

      if (!disp.IsNullorWhitespace(edu?.educationDegreeMinor?.display)) {
        this.childList.push(
          new Paragraph({
            children: [
              new TextRun({
                text: edu.educationDegreeMinor.display,
                font: { name: "Calibri" }
              })
            ],
            bullet: {
              level: 0
            }
          })
        );
      }

      if (!disp.IsNullorWhitespace(edu?.educationGPA.gpa))
        this.childList.push(
          new Paragraph({
            children: [
              new TextRun({
                text: "GPA: " + edu.educationGPA.display,
                font: { name: "Calibri" }
              })
            ],
            bullet: {
              level: 0
            }
          })
        );

      edu.educationAccolades?.forEach(acc => {
        this.childList.push(
          new Paragraph({
            children: [
              new TextRun({ text: acc.accolade, font: { name: "Calibri" } })
            ],
            bullet: {
              level: 0
            }
          })
        );
      });
    });

    return this;
  }

  addWorkExperience(experienceTitle, workExp) {
    this.setupDocument();

    this.childList.push(
      new Paragraph({
        children: [new TextRun({ text: experienceTitle })],
        heading: this.heading1
      })
    );

    workExp.forEach(work => {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: work.workGeneral.companyName,
              font: { name: "Calibri" }
            }),
            new TextRun({
              text:
                "\t" +
                work.workGeneral.companyCity +
                ", " +
                work.workGeneral.companyState +
                ", " +
                work.workGeneral.companyCountry,
              font: { name: "Calibri" }
            })
          ],
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position:
                TabStopPosition.MAX +
                convertInchesToTwip(
                  1 - this.leftMarginInches + (1 - this.rightMarginInches)
                ) // TabStopPosition.Max assumes 1 inch margins
            }
          ]
        })
      );

      let positions = [];
      work?.workRole?.positions?.forEach(role => {
        positions.push(
          new TextRun({
            text: buildPositionDisplay(role.title, role.role),
            font: { name: "Calibri" }
          })
        );
      });

      // This is dumb, but we're doing the date range to the right of the last line... I guess
      let i = 0;
      for (i = 0; i < positions.length; i++) {
        if (i + 1 == positions.length) {
          var endDateString = work.workGeneral.workingCurrently
            ? "Present"
            : dutil.dateToMonthYear(work.workGeneral.endDate);
          this.childList.push(
            new Paragraph({
              children: [
                positions[i],
                new TextRun({
                  text:
                    "\t" +
                    dutil.dateToMonthYear(work.workGeneral.startDate) +
                    " - " +
                    endDateString,
                  font: { name: "Calibri" }
                })
              ],
              tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position:
                    TabStopPosition.MAX +
                    convertInchesToTwip(
                      1 - this.leftMarginInches + (1 - this.rightMarginInches)
                    ) // TabStopPosition.Max assumes 1 inch margins
                }
              ]
            })
          );
        } else {
          this.childList.push(
            new Paragraph({
              children: [positions[i]]
            })
          );
        }
      }

      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: work.workResponsibilities.description,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );

      work.achievementList.forEach(acc => {
        this.childList.push(
          new Paragraph({
            children: [
              new TextRun({ text: acc.display, font: { name: "Calibri" } })
            ],
            bullet: {
              level: 0
            }
          })
        );
      });
    });

    return this;
  }

  addAdditionalExperience(
    additionalExperienceTitle,
    techSkills,
    languages,
    volunteer,
    licenseCerts,
    professionalOrg,
    interests
  ) {
    this.setupDocument();

    this.childList.push(
      new Paragraph({
        children: [new TextRun(additionalExperienceTitle)],
        heading: this.heading1
      })
    );

    if (!disp.IsNullorWhitespace(techSkills)) {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Hard and Technical Skills: " + techSkills,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );
    }

    if (!disp.IsNullorWhitespace(languages)) {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Languages: " + languages,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );
    }

    if (!disp.IsNullorWhitespace(volunteer)) {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Volunteer Work: " + volunteer,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );
    }

    if (!disp.IsNullorWhitespace(professionalOrg)) {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Professional Organizations: " + professionalOrg,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );
    }

    if (!disp.IsNullorWhitespace(licenseCerts)) {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Professional Licenses and Certifications: " + licenseCerts,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );
    }

    if (!disp.IsNullorWhitespace(interests)) {
      this.childList.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Interests: " + interests,
              font: { name: "Calibri" }
            })
          ],
          bullet: {
            level: 0
          }
        })
      );
    }

    return this;
  }

  buildSection() {
    this.setupDocument();
    this.document.addSection({
      margins: {
        top: convertInchesToTwip(this.topMarginInches),
        right: convertInchesToTwip(this.rightMarginInches),
        bottom: convertInchesToTwip(this.leftMarginInches),
        left: convertInchesToTwip(this.bottomMarginInches)
      },
      children: [...this.childList]
    });
    this.childList = null;
    return this;
  }

  saveFile(filename) {
    this.setupDocument();
    docx.Packer.toBlob(this.document).then(blob => {
      saveAs(blob, filename + ".docx");
    });
  }
}

// "privates"

function buildEduSchoolName(school, university) {
  let response = "";
  if (school?.length != 0) {
    response = `${school}`;
  }
  if (school?.length != 0 && university?.length != 0) {
    response += ", ";
  }
  if (university?.length != 0) {
    response += university;
  }

  return response;
}

function buildPositionDisplay(title, role) {
  let intStr = "";
  if (!disp.IsNullorWhitespace(title)) {
    intStr += title;

    if (!disp.IsNullorWhitespace(role)) {
      intStr += " - ";
    }
  }

  if (!disp.IsNullorWhitespace(role)) {
    intStr += role;
  }

  return intStr;
}
